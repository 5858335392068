.Home {
  // &::before {
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   content: '';
  //   width: 100vw;
  //   height: 30vh;
  //   background: #fff;
  //   border-radius: 50% 50% 0px 0px;
  //   // margin-bottom: calc(100vw / 2.5 / 1.25 * -1);
  //   // margin-left: -10vw;
  //   background-image: linear-gradient(to bottom, #f2f8fd, #ffffff 100%);
  //   z-index: -2;

  //   @media screen and (max-width: 768px) {
  //     margin-bottom: calc(100vw / 1.25 * -1 + 40px);
  //     width: 100vw;
  //     margin-left: 0px;
  //   }
  // }

  // &::after {
  //   position: absolute;
  //   bottom: 0;
  //   right: 0;
  //   content: '';
  //   width: 100vw;
  //   height: 100vh;
  //   z-index: -1;
  //   background: white;
  //   margin-bottom: calc(100vw * 2.25 / 2.5 / 1.25 * -1);
  // }

  background-image: linear-gradient(to top, #f2f8fd, #ffffff 50%);
  min-height: calc(100vh - 101px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-justify-content: space-between;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-flow: row wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row wrap;
  width: 100%;

  @media screen and (max-width: 768px) {
    padding-top: 40px;

    h1 {
      font-size: 2rem !important;
    }
  }
}