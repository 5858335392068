.Companies {
  // background-image: linear-gradient(to top, #f2f8fd, #ffffff 50%);

  .company {
    margin-bottom: 1rem;
  }

  .company-card {
    background: white;
    padding: 30px;
    // border-radius: 3px;
    // box-shadow: 1px 2px 3px #eee;

    border-left: 1px solid #dee2e6;
    @media screen and (max-width: 768px) {
      border-left: none;
      border-top: 1px solid #dee2e6;
    }
  }

  .logo {
    display: inline-block;
    background: white;
    padding: 8px;
    // border-radius: 4px;
    // border: 1px solid #ddd;
    opacity: 1;
    transition: opacity ease-in-out 0.15s;
    // box-shadow: 1px 2px 3px #eee;

    @media screen and (max-width: 768px) {
      width: 60%;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}
