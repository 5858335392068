$primary: #106a41;
$secondary: #272D2D;
$warning: #555c63;

@import "node_modules/bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3, h4, h5, h6, .btn {
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
}

h6 {
  position: relative;
  // display: flex;
  text-align: center;
  color: var(--gray-dark);
}

.basic-box {
  border-radius: 4px;
  border: 1px solid #ddd;
  box-shadow: 1px 2px 3px #eee;
  margin-bottom: 15px;
  padding: 15px;
}

h1 {
  position: relative;
  // display: flex;
  text-align: center;
  font-size: 2.25rem;
  color: var(--gray-dark);

  // &:before {
  //   content: '';
  //   position: relative;
  //   flex-grow: 1;
  //   height: 1px;
  //   top: 24px;
  //   width: 100px;
  //   background: black;
  //   margin-right: 40px;
  // }

  // &:after {
  //   content: '';
  //   position: relative;
  //   flex-grow: 1;
  //   height: 1px;
  //   top: 24px;
  //   width: 100px;
  //   background: black;
  //   margin-left: 40px;
  // }
}

a {
  color: var(--secondary);
}

label {
  font-weight: 500;
  font-size: 14px;
  color: var(--gray-dark);
}

.hide-mobile {
  @media screen and (max-width: 768px) {
    height: 0px;
    width: 0%;
    visibility: hidden;
    flex: none;
    position: absolute;
    z-index: -1000;
  }
}

.normal-padding {
  padding-top: 40px;
  padding-bottom: 80px;
}

.toast-container {
  .Toastify__close-button {
    color: white;
  }

  .Toastify__toast {
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    color: white;

    @media (min-width: 768px) {
      border-radius: 3px;
    }
  }

  .Toastify__toast--success {
    background: var(--primary);
  }

  .Toastify__toast--error {
    background: var(--danger);
    color: white;

    .Toastify__close-button {
      color: white;
    }
  }
}