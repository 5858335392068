.Team {
  position: relative;
  // background-image: linear-gradient(to top, #f2f8fd, #ffffff 50%);

  .top-bar-dec {
    width: 54px;
    height: 8px;
    background-color: #106a41;
  }

  .linkedin {
    cursor: pointer;
    opacity: 1;
    transition: opacity ease-in-out 0.15s;

    &:hover {
      opacity: 0.9;
    }
  }

  .team-member {
    text-align: center;
    margin-bottom: 30px;
    padding: 15px;
    border-radius: 4px;
    border: 1px solid #F1F7ED;
    cursor: pointer;
    background-color: white;
    transition: background-color ease-in-out 0.15s;
    position: relative;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.12);

    &:before {
      content: '';
      position: absolute;
      display: block;
      height: 100%;
      width: 8px;
      // border-top: 3px solid var(--secondary);
      // border-left: 3px solid var(--secondary);
      background: var(--secondary);
      top: 0;
      left: 0;
    }

    // &:before {
    //   content: '';
    //   position: absolute;
    //   display: block;
    //   height: 100%;
    //   width: 12px;
    //   border-top: 3px solid var(--secondary);
    //   border-left: 3px solid var(--secondary);
    //   top: 0;
    //   left: 0;
    // }

    // &:after {
    //   content: '';
    //   position: absolute;
    //   display: block;
    //   height: 100%;
    //   width: 12px;
    //   border-left: 3px solid var(--secondary);
    //   border-bottom: 3px solid var(--secondary);
    //   border-right: 3px solid var(--secondary);
    //   bottom: 0;
    //   left: 0;
    //   background: #EDF5FC;
    // }
    
    &:hover {
      background-color: whitesmoke;
    }

    .name {
      font-size: 21px;
      font-weight: 500;
    }

    .title {
      font-size: 18px;
    }
  }

  /* entire container, keeps perspective */
  .flip-container {
    perspective: 1000px;
  }
    /* flip the pane when hovered */
    .flip-container:hover .flipper, .flip-container.hover .flipper {
      transform: rotateY(180deg);
    }

  .flip-container, .front, .back {
    width: 320px;
    height: 480px;
  }

  /* flip speed goes here */
  .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;

    position: relative;
  }

  /* hide back of pane during swap */
  .front, .back {
    backface-visibility: hidden;

    position: absolute;
    top: 0;
    left: 0;
  }

  /* front pane, placed above back */
  .front {
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg);
  }

  /* back, initially hidden pane */
  .back {
    transform: rotateY(180deg);
  }
}