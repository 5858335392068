.IncubationProcess {
  // background-image: linear-gradient(to top, #f2f8fd, #ffffff 50%);

  @media screen and (max-width: 768px) {
    .mobile-text-center {
      text-align: center;
    }
  }

  .num, .num-primary {
    margin-bottom: 30px;
  }
}

.num {
  display: inline-block;
  border-radius: 50%;
  padding-top: 16px;
  height: 80px;
  width: 80px;
  background: var(--dark);
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  // text-decoration: underline;
}

.num-primary {
  display: inline-block;
  // border: 2px solid var(--primary);
  border-radius: 50%;
  padding-top: 16px;
  height: 80px;
  width: 80px;
  background: var(--primary);
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  // text-decoration: underline;
}

.num-gray {
  display: inline-block;
  // border: 2px solid var(--primary);
  border-radius: 50%;
  padding-top: 16px;
  height: 80px;
  width: 80px;
  background: var(--warning);
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  // text-decoration: underline;
}